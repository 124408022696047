import { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import { Row, Col, Tooltip, OverlayTrigger, Button, Modal, Table } from 'react-bootstrap';
import swal from 'sweetalert';
import { format } from 'date-fns';

import { Pagination } from '../../utils/pagination';
import { config } from "../../config/config";
import Loader from '../common/Loader';
import { userServices } from "../../services";
import { context } from '../../GlobalContext/GlobalContext';
import DefaultUser from "../../assets/images/img.png";
import { tr } from 'date-fns/locale';

const GameplanDetails = () => {
    const globalData = useContext(context)
    const [gameplan, setGameplan] = useState();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalpages: 0,
        totalRecord: 0
    });
    const [gameplanAttendees, setGameplanAttendees] = useState([]);
    const [gamePlanId, setGamePlanId] = useState('');
    const [prevPage, setPrevPage] = useState('');
    const [activeModal, setActiveModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setGamePlanId(id)
        getGameplanDetals(id);
        if (history.location.state.type === "suspendedGameplan") {
            globalData.setMainTab('suspendedGameplan');
            setPrevPage('suspendedGameplan');
        } else {
            globalData.setMainTab('gameplans');
            setPrevPage('gameplans');
        }
        globalData.setUserTabOpen(false);
    }, []);

    useEffect(() => {
        getGameplanAttendees(id);
    }, [globalData.visibilityStatusFilter]);

    function getGameplanDetals(gameplanId) {
        let params = {
            gameplan_id: gameplanId
        };
        setLoading(true);
        userServices.GetGameplanDetails(params).then((res) => {
            setGameplan(res.data.data)
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
            setGameplan({});
        })
    }

    const suspendedGameplan = (type) => {
        let params = {
            gameplan_id: id,
            gameplan_visibility_status: {
                gameplan_visibility_status: type
            }
        }
        setLoading(true);
        userServices.PutSuspendedGameplan(params).then((res) => {
            if (res) {
                getGameplanDetals(id);
            }
        }).catch(error => {
            setLoading(false)
            swal("Error", "Server error Please refresh the page", "error")
        })
    }

    function getGameplanAttendees(gameplanId) {
        let params = {
            gameplan_id: gameplanId,
            payout: globalData.visibilityStatusFilter
        }
        userServices.GamePlansAttendees(params).then((res) => {
            setGameplanAttendees(res.data.data);
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
            setLoading(false);
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: parseInt(event.selected),
            }
        });
        // setIsPaginationTrigger(true);
    };

    const returnDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
        // DO NOT REMOVE CODE 
        //    return new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(date),'MM/dd/yyyy');
    }

    const handleBackBtn = () => {
        if (history.location.state) {
            if (history.location.state.type === "gameplan_list" || history.location.state.prevType === "gameplan_list") {
                history.push('/gameplans');
            }
            if (history.location.state.type === "suspendedGameplan" || history.location.state.prevType === "suspendedGameplan") {
                history.push('/suspendedGameplan');
            }
        } else {
            history.push('/gameplans')
        }
    }

    function toggleModal(status) {
        setActiveModal(status);
    }

    function adminRefund(data) {
        userServices.adminRefund(data).then((response) => {
            toggleModal(false)
            setLoading(false);
            swal("Success", "Refund initiated successfully", "success");
            getGameplanAttendees(id);
            // getGameplanDetals(id);
            // toggleModal(false);

        }).catch((error) => {
            setLoading(false);
        })
    }

    function initateRefund(data) {
        setLoading(true);
        adminRefund(data);
    }


    let gamePlanStatus = gameplan ? gameplan.end_date_if_it_is_on ? format(new Date(), 'MM/dd/yyyy') <= returnDate(gameplan.end_date_if_it_is_on) ? "ACTIVE" : "COMPLETED" : false : false;

    return (
        <>
            {loading && <Loader />}
            {gameplan ?
                <>
                    <div className='sale_graph transaction_box mb-3'>
                        <div className='bact-div'>
                            <Button onClick={() => handleBackBtn()} className='back-bt' variant="unset"> <i className="fa fa-angle-left" aria-hidden="true" />  Back</Button>
                        </div>
                        <span className='suspend-box'>Suspend:
                            <OverlayTrigger
                                overlay={<Tooltip id="btn-del-1">{gameplan.gameplan_visibility_status === "S" ? "De-Active" : "Active"}</Tooltip>}>
                                <div className="togglebtn">
                                    <label className="switch">
                                        <input type="checkbox" checked={gameplan.gameplan_visibility_status === "A" ? false : true}
                                            onChange={(e) => {
                                                gameplan.gameplan_visibility_status === "S" ? suspendedGameplan("A") : suspendedGameplan("S")
                                            }} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </OverlayTrigger>
                        </span>
                        {/* <Row className='mt-4'>
                            <Col className='m-0 p-0'>
                                <h2 className='mb-2'>Gameplan Details</h2>
                            </Col>
                            <Col md={12}>
                                {gameplan?.gameplan_image ?
                                    <img
                                        className="profile_picture"
                                        src={config.imageUrl + gameplan?.gameplan_image}
                                        width={100}
                                        height={100}
                                        alt="Profile Picture"
                                    />
                                    :
                                    <img
                                        className="profile_picture"
                                        src={require('../../assets/images/img.png')}
                                        width={100}
                                        height={100}
                                        alt="Profile Picture"
                                    />
                                }
                            </Col>
                        </Row> */}
                        <Row className='justify-content-between align-items-center'>
                            <Col md={12}>
                                <h2 className='mb-2'>Gameplan Details</h2>
                            </Col>
                            <Col md={6} >
                                <span className='game-txt d-block'>Gameplan image</span>
                                {gameplan?.gameplan_image ?
                                    <img
                                        className="profile_picture gamplan-img"
                                        src={config.imageUrl + gameplan?.gameplan_image} width={100}
                                        height={100}
                                        alt="Profile Picture"
                                        onError={(event) => { event.target.src = DefaultUser }}
                                    />
                                    :
                                    <img
                                        className="profile_picture"
                                        src={require('../../assets/images/img.png')}
                                        width={100}
                                        height={100}
                                        alt="Profile Picture"
                                    />
                                }

                            </Col>

                        </Row>
                        <Row className='user-detail-main'>
                            <Col md={12} className="mb-4 mt-1">
                                <button className="btn btn-primary" onClick={() => {
                                    history.push({
                                        pathname: `/userdetails/${gameplan?.user._id}`,
                                        state: {
                                            type: `gameplan/${gamePlanId}`,
                                            prevType: history.location.state ? history.location.state.type : 'gameplan_list'
                                        }
                                    })
                                }}>
                                    Gameplan Creator{gameplan.user ? ` : ${gameplan.user.first_name} ${gameplan.user.last_name}` : ``}
                                </button>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Title:<h6>{gameplan?.title}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Description:<h6>{gameplan?.description}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Location:<h6>{gameplan?.gameplan_location}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Visibility Status:
                                    <h6>
                                        {
                                            gamePlanStatus ? gamePlanStatus : gameplan.end_date === "Never" ? "ACTIVE" : ""
                                        }
                                        {/* {gameplan?.gameplan_visibility_status == 'A'
                                            ?
                                            "ACTIVE"
                                            :
                                            gameplan?.gameplan_visibility_status == "P"
                                                ?
                                                "PAUSED"
                                                :
                                                "N/A"
                                        } */}
                                    </h6>
                                </span>
                            </Col>
                            <Col md={4} className="mb-3">
                                {/*     new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(gameplan.createdAt ? gameplan.createdAt : "-")) */}
                                <span>Created At:<h6>{gameplan.createdAt ? returnDate(gameplan.createdAt) : "-"}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Updated At:<h6>{gameplan.updatedAt ? returnDate(gameplan.updatedAt) : "-"}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Number of attendees:<h6>{gameplan.number_of_attendes}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Entry price per person:<h6>{gameplan?.entry_price_per_person}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Total Duration:<h6>{gameplan?.total_duration_for_location}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Start Date:<h6>{gameplan.start_date ? returnDate(gameplan.start_date) : "-"}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Repetition Schedule:<h6>{gameplan?.repeat_gameplan_schedule}</h6></span>
                            </Col>
                            {gameplan.days_if_gameplan_weekly_or_bi_weekly?.length && gameplan.days_if_gameplan_weekly_or_bi_weekly[0] != null
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Repetition days:<h6>{gameplan.days_if_gameplan_weekly_or_bi_weekly ? gameplan.days_if_gameplan_weekly_or_bi_weekly.filter(e => { return (<span>{e}</span>) }).join(", ") : null}</h6></span>
                                </Col>
                                : null}
                            {gameplan.start_date_if_monthly
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Start Date If Monthly:<h6>{gameplan.start_date_if_monthly ? returnDate(gameplan.start_date_if_monthly) : "-"}</h6></span>
                                </Col>
                                : null}
                            {console.log(gameplan.custom_dates_for_gamplan_if_custom)}
                            {gameplan.custom_dates_for_gamplan_if_custom?.length
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Custom Gameplan Dates:<h6>{gameplan.custom_dates_for_gamplan_if_custom ? gameplan.custom_dates_for_gamplan_if_custom.map((e, index) => { return (<span key={e}>{index > 0 ? ", " : ''} {returnDate(e.split("-").join("/"))}</span>) }) : null}</h6></span>
                                </Col>
                                : null}
                            {gameplan.end_date_if_it_is_on
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Ending on:<h6>{gameplan.end_date_if_it_is_on ? returnDate(gameplan.end_date_if_it_is_on) : "-"}</h6></span>
                                </Col>
                                : null}
                            {gameplan.end_date != "On"
                                ?
                                <Col md={4} className="mb-3">
                                    {console.log('gameplan', gameplan.end_date)}
                                    <span>End Date:<h6>{gameplan.end_date}</h6></span>
                                </Col>
                                : null}
                            {gameplan.gamePlanBusiness?.length
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Businesses:<h6>{gameplan.gamePlanBusiness ? gameplan.gamePlanBusiness.map(e => { return (<span className="perk-style">{e.business_name}</span>) }) : null}</h6></span>
                                </Col>
                                : null
                            }
                            {gameplan.perks[0]?.length > 0 &&
                                <Col md={4} className="mb-3">
                                    <span>Perks:<h6>{gameplan.perks
                                        ?
                                        gameplan.perks[0].split("\n").map(perk => {
                                            if (perk.length) {
                                                return (
                                                    <span className='perk-style' key={perk}>{perk}</span>
                                                )
                                            }
                                        })
                                        :
                                        null}</h6></span>
                                </Col>
                            }

                            {
                                prevPage ? prevPage === "suspendedGameplan" ?
                                    gameplanAttendees.length ?
                                        <Col md={4} className="mb-3">
                                            <Button className='mt-4' onClick={() => toggleModal(true)}>Refund</Button>
                                        </Col> : '' : '' : ''
                            }
                        </Row>
                    </div>

                    <div className='sale_graph transaction_box mb-3 '>
                        <div className='align-items-center row'>
                            <Col sm={6}>
                                <h2 className="mb-2">Bookings:</h2>
                            </Col>
                            {/* <Col sm={2} className="filters-style">
                                Filter by status:
                                <Dropdown onSelect={(e) => {
                                    setLoading(true);
                                    globalData.setVisibilityStatusFilter(e);
                                }}>
                                    <Dropdown.Toggle>
                                        {globalData.visibilityStatusFilter === "SUCCESS" ? "Success" : "All"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="" className={globalData.visibilityStatusFilter === "" ? 'theme-background-color' : ''}>All</Dropdown.Item>
                                        <Dropdown.Item eventKey="SUCCESS" className={globalData.visibilityStatusFilter === "A" ? 'theme-background-color' : ''}>Success</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                        </div>
                        <Row md={8} className="mb-8">
                            <>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Booked on</th>
                                                <th>No of Attendees</th>
                                                <th>Booking date</th>
                                                <th>Booking status</th>
                                                <th>Price paid</th>
                                                <th>Payment status</th>
                                                <th>Payout status</th>
                                                <th>Total price</th>
                                                <th>Booked by</th>
                                            </tr>
                                        </thead>
                                        {gameplanAttendees?.length > 0 ?
                                            <tbody>
                                                {gameplanAttendees?.map((attendee, key) => {
                                                    console.log('attendee...', attendee.booking_data.user);
                                                    return (
                                                        <tr id={key} key={`${attendee._id}_${key}`}>
                                                            <td>
                                                                {returnDate(attendee.booking_data.createdAt)}
                                                            </td>
                                                            <td>
                                                                <span>{attendee.booking_data.number_of_attendes}</span>
                                                            </td>
                                                            <td>
                                                                {returnDate(attendee.booking_data.booking_date)}
                                                            </td>
                                                            <td>
                                                                <span className='text-capitalize'>{attendee.booking_data.booking_status.toLowerCase()}</span>
                                                            </td>
                                                            {/* <td>
                                                                <span>{attendee.booking_data.payment_method}</span>
                                                            </td>
                                                            <td>
                                                                <span className='text-capitalize'>{attendee.booking_data.payment_status.toLowerCase()}</span>
                                                            </td>
                                                            <td>
                                                                <span>{JSON.stringify(attendee.booking_data.payout_initiated)}</span>
                                                            </td>
                                                            <td>
                                                                <span className='text-capitalize'>{attendee.booking_data.payout_status.toLowerCase()}</span>
                                                            </td> */}
                                                            <td>
                                                                <span>$ {attendee.booking_data.total_price}</span>
                                                            </td>
                                                            <td>
                                                                <span className='text-capitalize'>{attendee.booking_data.payment_status.toLowerCase()}</span>
                                                            </td>
                                                            <td>
                                                                <span className='text-capitalize'>{attendee.booking_data.payout_status.toLowerCase()}</span>
                                                            </td>
                                                            <td>
                                                                <span>$ {attendee.total_price}</span>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger overlay={<Tooltip id="btn-view-1">View User Details</Tooltip>}>
                                                                    <Link to={{ pathname: `/userdetails/${attendee.booking_data.user._id}`, state: { type: `gameplan/${gamePlanId}`, prevType: history.location.state ? history.location.state.type : '', showGamePlanBooking: true, gamePlanId: gamePlanId } }}><i className="fa fa-eye view-eye" aria-hidden="true" /></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            :
                                            <tr>
                                                <td className="text-center" colSpan={12}>No Data Found</td>
                                            </tr>
                                        }
                                    </Table>
                                    {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} currentPage={paginationObj.page} handlePageChange={handlePageChange} /> : null}
                                </div>
                            </>
                        </Row>
                    </div>


                    <Modal show={activeModal} onHide={() => toggleModal(false)} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Gameplan Users</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                gameplanAttendees.length ?
                                    gameplanAttendees.map((value, index) => {
                                        return (
                                            <>
                                                {value?.booking_data?.booking_status == "CANCELLED" ?
                                                    null :
                                                    <div className='row mt-2' key={`${value._id}_${index}`}>
                                                        <div className='col-md-8'>
                                                            {value.booking_data.user.first_name} {value.booking_data.user.last_name}
                                                        </div>
                                                        <div className='col-md-4 text-right'>
                                                            <Button onClick={() => initateRefund(value.booking_data._id)}>Refund</Button>
                                                        </div>

                                                    </div>}
                                            </>
                                        )
                                    })
                                    : 'No user  available'
                            }

                        </Modal.Body>
                    </Modal>

                </>
                : null}
        </>
    )
}

export default GameplanDetails;