import axios from "axios";
import { config } from "../config/config";
import {
    jsonHeaders
    // , multipartHeaders 
} from "../helpers";

function configJsonHeaders() {
    return { headers: jsonHeaders() }
}

// function formHeaders() {
//     return { headers: multipartHeaders() }
// }

export const userServices = {
    AdminLoginApi,
    GetUsersListForAdmin,
    GetUserDetails,
    EnableDisableUser,
    UpdateAdminDetails,
    ChangePassword,
    ApproveDisapproveUser,
    IncompleteProfileUsers,
    GetAdminUserDetails,
    GetPendingUsersList,
    GetApprovedUsersList,
    GetAllGameplans,
    GetGameplanDetails,
    GetUserGameplans,
    GamePlansAttendees,
    GetAllBooking,
    GetBookingDetails,
    GetBookingDetailById,
    GetSuspendedGameplan,
    GetAllTransactions,
    PutSuspendedGameplan,
    GetTransactionsDetails,
    GetTransactionsPayout,
    GetPayoutDetails,
    GetBookingPaymentDetails,
    GetContactUsList,
    GetContactUsDetails,
    PutContactMessage,
    userBooking,
    getUserGameplanList,
    adminRefund,
    addCommission,
    getCommission,
    updateCommission,
    paymentHold,
    GetAllHoldPayment,
    getUserCount,
    getTransactionsData

}

function AdminLoginApi(params) {
    let url = `${config.apiUrl}/auth/adminLogin`
    return axios.post(url, params, configJsonHeaders);
}

function GetUsersListForAdmin(params) {
    let url = `${config.apiUrl}/auth/getApprovedUsersList?type=${params.type}&pageNo=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ``}`
    return axios.get(url, configJsonHeaders());
}

function GetUserDetails(params) {
    let url = `${config.apiUrl}/auth/getUserDetail?user_id=${params.user_id}`
    return axios.get(url, configJsonHeaders());
}

function GetUserGameplans(params) {
    let url = `${config.apiUrl}/auth/getUserGamePlan?user_id=${params.user_id}&page=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ''}`
    return axios.get(url, configJsonHeaders());
}

function GetAdminUserDetails(params) {
    let url = `${config.apiUrl}/auth/getAdminUserDetail?user_id=${params.user_id}`
    return axios.get(url, configJsonHeaders());
}

function EnableDisableUser(params) {
    let url = `${config.apiUrl}/auth/enableDisableUser?status=${params.status}&user_id=${params.user_id}`
    return axios.put(url, {}, configJsonHeaders());
}

function UpdateAdminDetails(formData) {
    let url = `${config.apiUrl}/auth/updateAdminProfile`
    return axios.put(url, formData, configJsonHeaders());
}

function ChangePassword(params) {
    let url = `${config.apiUrl}/auth/changePassword`
    return axios.put(url, params, configJsonHeaders());
}

function ApproveDisapproveUser(params) {
    let url = `${config.apiUrl}/auth/approveDisapproveUser?user_id=${params.user_id}&user_profile_status=${params.userProfileStatus}`
    return axios.put(url, params, configJsonHeaders());
}

function IncompleteProfileUsers(params) {
    let url = `${config.apiUrl}/auth/getRegisteredUsersWhoHavePendingCreateProfile?pageNo=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ``}`
    return axios.get(url, configJsonHeaders());
}

function GetPendingUsersList(params) {
    let url = `${config.apiUrl}/auth/getPendingUsersList?pageNo=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ''}`
    return axios.get(url, configJsonHeaders());
}

function GetApprovedUsersList(params) {
    let url = `${config.apiUrl}/auth/getApprovedUsersList?type=${params.type}&pageNo=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ''}`
    return axios.get(url, configJsonHeaders());
}

function GetAllGameplans(params) {
    let url = `${config.apiUrl}/admin/gamePlans?pageNo=${params.pageNo}&size=${params.size}${params.search ? `&search=${params.search}` : ``}${params.status ? `&status=${params.status}` : ``}`
    return axios.get(url, configJsonHeaders());
}

function GetAllBooking(params) {
    let url = `${config.apiUrl}/payment/allBookings?page=${params.page}&size=${params.size}${params.search ? `&search=${params.search}` : ``}${params.status ? `&booking=${params.status}` : ``}`
    return axios.get(url, configJsonHeaders());
}

function GetGameplanDetails(params) {
    let url = `${config.apiUrl}/admin/gamePlan/${params.gameplan_id}`
    return axios.get(url, configJsonHeaders());
}
function GetBookingDetails(params) {
    let url = `${config.apiUrl}/payment/bookings/${params.gameplan_id}`
    return axios.get(url, configJsonHeaders());
}

function GamePlansAttendees(params) {
    let url = `${config.apiUrl}/admin/gamePlans/${params.gameplan_id}${params.payout ? `?payout_status=${params.payout}` : ``}`
    return axios.get(url, configJsonHeaders());
}
function GetBookingDetailById(params) {
    let url = `${config.apiUrl}/payment/bookings/${params.booking_id}`;
    return axios.get(url, configJsonHeaders());
}
function GetSuspendedGameplan(params) {
    let url = `${config.apiUrl}/admin/gamePlansSuspend?page=${params.page}&size=${params.size}${params.search ? `&search=${params.search}` : ``}`;
    return axios.get(url, configJsonHeaders());
}

function GetAllTransactions(params) {
    let url = `${config.apiUrl}/payment/allTransactions?page=${params.page}&size=${params.size}${params.search ? `&search=${params.search}` : ``}${params.status ? `&filter_duration=${params.status}` : ''}${params.payment_status ? `&payment=${params.payment_status}` : ''}`;
    return axios.get(url, configJsonHeaders());
}

function GetAllHoldPayment(params) {
    let url = `${config.apiUrl}/admin/bookings/onHoldList?page=${params.page}&size=${params.size}${params.search ? `&search=${params.search}` : ``}${params.status ? `&filter_duration=${params.status}` : ''}${params.payment_status ? `&payment=${params.payment_status}` : ''}`;
    return axios.get(url, configJsonHeaders());
}


function PutSuspendedGameplan(params) {
    let url = `${config.apiUrl}/admin/suspendGameplan/${params.gameplan_id}`;
    return axios.patch(url, params.gameplan_visibility_status, configJsonHeaders());
}

function GetTransactionsDetails(params) {
    let url = `${config.apiUrl}/payment/transactions/${params.gameplan_id}`;
    return axios.get(url, configJsonHeaders());
}

function GetTransactionsPayout(params) {
    let url = `${config.apiUrl}/payment/payout?page=${params.page}&size=${params.size}${params.status ? `&payout_status=${params.status}` : ''}${params.search ? `&search=${params.search}` : ``}`;
    return axios.get(url, configJsonHeaders());
}


function GetPayoutDetails(params) {
    let url = `${config.apiUrl}/payment/payout/${params.gameplan_id}`;
    return axios.get(url, configJsonHeaders());
}

function paymentHold(params) {
    let url = `${config.apiUrl}/admin/holdUnholdPayout/${params.id}`;
    return axios.put(url, params.data, configJsonHeaders());
}


function GetBookingPaymentDetails(params) {
    let url = `${config.apiUrl}/payment/transactions/${params.gameplan_id}`;
    return axios.get(url, configJsonHeaders());
}

function GetContactUsList(params) {
    let url = `${config.apiUrl}/auth/contactUs?page=${params.page}&size=${params.size}${params.search ? `&search=${params.search}` : ``}`;
    return axios.get(url, configJsonHeaders());
}
function getUserCount() {
    let url = `${config.apiUrl}/admin/getTotalUsers`;
    return axios.get(url, configJsonHeaders());
}
function getTransactionsData(params) {
    let url = `${config.apiUrl}/admin/getTransactionsData?filter=${params.status}`;
    return axios.get(url, configJsonHeaders());
}
function GetContactUsDetails(params) {
    let url = `${config.apiUrl}/auth/contactUs/${params.contact_id}`;
    return axios.get(url, configJsonHeaders());
}
function PutContactMessage(params) {
    let url = `${config.apiUrl}/auth/sendMail/${params.contact_id}`;
    return axios.put(url, params.data, configJsonHeaders());
}
function userBooking(params) {
    let url = `${config.apiUrl}/admin/bookings/${params}`;
    return axios.get(url, configJsonHeaders());
}

function getUserGameplanList(id) {
    let url = `${config.apiUrl}/admin/userGameplan/${id}`;
    return axios.get(url, configJsonHeaders());
}

function adminRefund(params) {
    let url = `${config.apiUrl}/admin/refund/${params}`
    return axios.post(url, {}, configJsonHeaders());
}

function getCommission() {
    let url = `${config.apiUrl}/admin/getCommission`;
    return axios.get(url, configJsonHeaders());
}

function addCommission(params) {
    let url = `${config.apiUrl}/admin/addCommission`;
    return axios.post(url, params, configJsonHeaders());
}

function updateCommission(params, id) {
    let url = `${config.apiUrl}/admin/updateCommission/${id}`;
    return axios.put(url, params, configJsonHeaders());
}