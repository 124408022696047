import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Pagination } from '../../utils/pagination';
import { format } from 'date-fns';
import { Container, Row, Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from 'sweetalert';

import { userServices } from "../../services";
import Loader from '../common/Loader';
import { USER_TYPE } from '../../assets/constants/constants';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';
// CSS
import '../../assets/css/style.css';

const NativeUnapprovedUsers = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: globalData.pageSelected || 0,
        totalpages: 0,
        totalRecord: 0
    });

    // useEffect(()=>{
    //     allUsersList()
    //     globalData.setSubTab("native_users")
    //     globalData.setMainTab('all_users')
    //     globalData.setUserTypeTab("unapproved")
    // }, [searchKeyword, isPaginationTrigger, window.location.pathname])

    useEffect(() => {
        allUsersList();
        globalData.setSubTab("native_users");
        globalData.setMainTab('all_users');
        globalData.setUserTypeTab("unapproved");
    }, []);

    useEffect(() => {
        if (isPaginationTrigger) {
            allUsersList();
        }
    }, [isPaginationTrigger,]);

    function allUsersList() {
        let params = {
            type: USER_TYPE.NATIVE,
            pageNo: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword
        };
        setLoading(true);
        userServices.GetPendingUsersList(params).then((res) => {
            if (res.status === 200) {
                setUsersList(res.data.data);
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.usersListCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.usersListCount
                    }
                });
            }
            setIsPaginationTrigger(false);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setIsPaginationTrigger(false);
            if (error?.response?.status === 401) {
                localStorage.clear();
                history.push("/");
            } else {
                swal("Error", "Server error", "error");
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: parseInt(event.selected),
            }
        });
        globalData.setPageSelected(parseInt(event.selected));
        setIsPaginationTrigger(true);
    };

    const approveDisapproveUser = (params) => {
        setLoading(true);
        userServices.ApproveDisapproveUser(params).then((res) => {
            swal(params.userProfileStatus === "APPR" ? "Success" : "Error", `User profile ${params.userProfileStatus === "APPR" ? "approved" : "unapproved"} successfully`, params.userProfileStatus === "APPR" ? "success" : "Error")
            allUsersList();
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
        })
    };

    // handle page header search input
    const handlePageHeaderSearchInput = (event) => {
        let { value } = event.target;
        setSearchKeyword(value);
    }


    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        allUsersList();
    }

    const resetSearch = () => {
        setSearchKeyword("")
        // setIsSearchStart(false)
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: 0,
            }
        });
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="sale_graph transaction_box mb-3">
                                <Row className='align-items-center'>
                                    <Col sm={6}>
                                        <h2 className="mb-2">Native unverified users</h2>
                                    </Col>

                                    <Col sm={4} className="search-box">
                                        <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                            <input type="text" placeholder='Search by Name/Email/Phone No.' value={searchKeyword} className="form-control" onChange={(event) => handlePageHeaderSearchInput(event)} required />
                                            {
                                                isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                            }
                                        </form>
                                    </Col>

                                    {/* KUNDAN CODE DO NOT REMOVE */}
                                    {/* <Col sm={4} className="search-box">
                                        <Form.Control placeholder='Search by Name/Email/Phone No.' value={searchKeyword}
                                            onChange={e => {
                                                setSearchKeyword(e.target.value)
                                            }}
                                        />
                                        {isSearchStart ?
                                            <i className="fa fa-close" aria-hidden="true" onClick={() => {
                                                setSearchKeyword("")
                                                setIsSearchStart(false)
                                                setPaginationObj(prevPaginationObj => {
                                                    return {
                                                        ...prevPaginationObj,
                                                        page: 0,
                                                    }
                                                });
                                                setIsPaginationTrigger(true);
                                            }} />
                                            :
                                            <i className="fa fa-search" aria-hidden="true" onClick={() => {
                                                if (searchKeyword) {
                                                    setIsSearchStart(true)
                                                    allUsersList()
                                                }
                                            }} />
                                        }
                                    </Col> */}
                                </Row>

                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email/Phone No.</th>
                                                <th>Created On</th>
                                                <th>Last Activity On</th>
                                                <th>Gender</th>
                                                <th>Neighbourhood Area</th>
                                                <th>Profile Action</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersList.length
                                                ?
                                                usersList.map((user, key) => {
                                                    return (
                                                        <tr id={key} key={`${user._id}_${key}`}>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{user.first_name + " " + user.last_name}</Tooltip>
                                                                    }>
                                                                    <span>{user.first_name + " " + user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{user.email ? user.email : user.phone_number}</Tooltip>
                                                                    }>
                                                                    <span>{user.email ? user.email : user.phone_number}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(user.createdAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td>
                                                                {format(new Date(user.updatedAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td>{user.gender}</td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{user.user_neighbourhood_area}</Tooltip>
                                                                    }>
                                                                    <span>{user.user_neighbourhood_area}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">Allow to approve this profile</Tooltip>
                                                                    }>
                                                                    {user.user_profile_status === "APPR"
                                                                        ?
                                                                        "Approved"
                                                                        :
                                                                        <div className="togglebtn">
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                    disabled={user.user_profile_status === "APPR" ? true : false}
                                                                                    checked={user.user_profile_status === "APPR" ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let updateProfileStatus;
                                                                                        if (user.user_profile_status === "PEND") {
                                                                                            updateProfileStatus = "APPR"
                                                                                        } else {
                                                                                            updateProfileStatus = ""
                                                                                        }
                                                                                        approveDisapproveUser({
                                                                                            user_id: user._id,
                                                                                            userProfileStatus: updateProfileStatus
                                                                                        });
                                                                                    }} />
                                                                                <span className="slider round" />
                                                                            </label>
                                                                        </div>
                                                                    }
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-view-1">View user details</Tooltip>
                                                                    }>
                                                                    <Link to={`/userdetails/${user._id}`}><i className="fa fa-eye view-eye" aria-hidden="true" /></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} currentPage={paginationObj.page} handlePageChange={handlePageChange} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default NativeUnapprovedUsers;